module.exports = {
    errors: {
        required: "Pole jest wymagane",
        email: "Wpisz prawidłowy adres e-mail",
        agreement: "Zgoda jest wymagana",
    },
    mail: {
        success: "Dziękuję. Twoja wiadomość została wysłana",
        serverError: "Ups! Coś poszło nie tak i nie mogliśmy wysłać Twojej wiadomości. Spróbuj ponownie później.",
        formError: "Ups. Coś wypełniłeś nieprawidłowo lub nie wypełniłeś wcale. Uzupełnij wszystkie pola jeszcze raz i spróbuj ponownie.",
        error: "Problem z przetworzeniem formularza.",
    },
    rodo: {
        agreement: "Wyrażam zgodę na przetwarzanie danych osobowych zgodnie z ustawą o ochronie danych osobowych w związku z wysłaniem zapytania przez formularz kontaktowy. Podanie danych jest dobrowolne, ale niezbędne do przetworzenia zapytania. Zostałem poinformowany, że przysługuje mi prawo dostępu do swoich danych, możliwości ich poprawiania, żądania zaprzestania ich przetwarzania. <br/> Administratorem danych osobowych jest: <br/> <strong>Bat in Web Maciej Szałkowski ul. Kazimierza Pułaskiego 14A lok. 2 15-338 Białystok Podlaskie Polska</strong>",
        checkbox: "Zapoznałem się z informacją o administratorze i przetwarzaniu danych."
    }
}

import React from "react";
import SEO from "../../components/seo";
import Image from "../../components/image/image";
import ContactForm from "../../components/contact-form";
import {errors, mail, rodo} from "../../data/contact-messages/pl";
import {GetSimpleImage} from "../../helpers/get-simple-image";
import "./contact.scss";

const ContactPage = (props) => {
    return (
        <div location={props.location}>
            <SEO title="Kontakt" language="pl"/>
            <div className="contact-background">
                <div className="container contact-wrap">
                    <div className="contact">
                        <div className="details">
                            <div>
                                <Image fluid image={GetSimpleImage("logo.png")[0].node.fluid} alt="logo"
                                       className="logo"/>
                                <p className="detail-hero">
                                    Maciej Szałkowski
                                </p>
                            </div>
                            <p className="adress">
                                ul. Swobodna 34/27<br/>
                                15-756 Białystok<br/>
                                Polska
                            </p>
                            <div>
                                <div>
                                    <span>Tel:&nbsp;</span>
                                    <a href="tel:+48691836758">
                                        +48 691 836 758
                                    </a>
                                </div>
                                <div>
                                    <span>e-mail:&nbsp;</span>
                                    <a href="mailto:contact@batinweb.com">
                                        contact@batinweb.com
                                    </a>
                                </div>
                            </div>
                            <p className="company-numbers">
                                NIP:&nbsp;542-311-56-12<br/>
                                REGON:&nbsp;380526561
                            </p>
                        </div>
                        <div className="contact-form">
                            <h2 className="title">
                                Skontaktuj się z nami:
                            </h2>
                            <ContactForm
                                name="Imię i nazwisko"
                                emailPlaceholder="e-mail"
                                messagePlaceholder="Wiadomość"
                                btnText="Wyślij"
                                sentMessages={mail}
                                validateErrors={errors}
                                rodo={rodo}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactPage;
